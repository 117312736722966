body {
	font-family: 'Open Sans', sans-serif;
	color: #444;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 400;
}

h1 {
	margin-top: 0px;
	margin-bottom: 1rem;
	color: #014976;
	font-size: 2.25rem;
	line-height: 2.75rem;
	font-weight: 600;
}

h2 {
	margin-top: 0px;
	margin-bottom: 1rem;
	color: #014976;
	font-size: 2rem;
	line-height: 2.5rem;
	font-weight: 600;
}

h3 {
	margin-top: 0px;
	margin-bottom: 1rem;
	color: #014976;
	font-size: 1.75rem;
	line-height: 2.25rem;
	font-weight: 600;
}

h4 {
	margin-top: 0px;
	margin-bottom: 1rem;
	color: #014976;
	font-size: 1.5rem;
	line-height: 2rem;
	font-weight: 600;
}

h5 {
	margin-top: 0px;
	margin-bottom: 1rem;
	color: #014976;
	font-size: 1.25rem;
	line-height: 1.75rem;
	font-weight: 600;
}

h6 {
	margin-top: 0px;
	margin-bottom: 1rem;
	color: #014976;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 600;
}

p {
	margin-top: 0px;
	margin-bottom: 1rem;
	line-height: 1.5rem;
}

a {
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #549fc4;
	font-weight: 600;
	letter-spacing: 0.01rem;
	text-decoration: none;
}

a:hover {
	color: #50748a;
	text-decoration: none;
}

ul {
	margin-top: 0.5rem;
	margin-bottom: 0.75rem;
	padding-left: 1.25rem;
}

ol {
	margin-top: 0.5em;
	margin-bottom: 0.75em;
	padding-left: 1.125em;
}

li {
	margin-bottom: 0.25em;
}

label {
	display: block;
	margin-bottom: 0.25em;
	line-height: 1.25em;
	font-weight: 700;
}

strong {
	font-weight: 700;
	letter-spacing: 0.02em;
}

em {
	font-style: italic;
	letter-spacing: 0.02em;
}

blockquote {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1.25rem;
	border-left-color: #014976;
	background-color: rgba(84, 159, 196, 0.07);
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: normal;
}

.button {
	display: inline-block;
	margin-top: 1rem;
	margin-right: 1rem;
	margin-bottom: 1rem;
	padding: 0.5rem 1.25rem;
	background-color: #014976;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #fff;
	line-height: 1.5rem;
	font-weight: 600;
	text-align: center;
	text-decoration: none;
}

.button:hover {
	background-color: #28313b;
	color: #fff;
}

.button.secondary {
	background-color: #50748a;
}

.button.secondary:hover {
	background-color: #28313b;
}

.button.header-search-button {
	width: 2.5rem;
	margin-top: 0em;
	margin-bottom: 0em;
	padding-top: 0em;
	padding-bottom: 0em;
	background-color: hsla(0, 0%, 100%, 0.15);
	background-image: url('/20160710165515/assets/images/search_wht.svg');
	background-position: 50% 50%;
	background-size: 1em 1em;
	background-repeat: no-repeat;
	color: transparent;
	font-size: 0.75rem;
	line-height: 1em;
	text-transform: uppercase;
}

.button.header-search-button:hover {
	background-color: rgba(33, 41, 49, 0.25);
}

.container {
	display: block;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
	padding-right: 10px;
	padding-left: 10px;
}

.container.header-wrap {
	position: relative;
	display: block;
	padding-right: 0px;
	padding-left: 0px;
}

.container.content-boxes-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 0px;
	padding-bottom: 2rem;
	padding-left: 0px;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.container.centered {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	text-align: center;
}

.container.footer-flex {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.nav-section {
	background-color: #fff;
}

.nav-link {
	display: inline-block;
	margin-right: 1px;
	padding: 0.75rem 1.25rem;
	-webkit-box-flex: 1;
	-webkit-flex: 1 0 auto;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	color: #014976;
	font-size: 1rem;
	line-height: 1.25rem;
	font-weight: 400;
	text-align: center;
}

.nav-link:hover {
	background-color: rgba(93, 127, 165, 0.15);
	box-shadow: 0 -3px 0 0 #014976;
}

.nav-link.w--current {
	margin-right: 1px;
	padding: 0.75rem 1.25rem;
	background-color: rgba(93, 127, 165, 0.15);
	box-shadow: 0 -3px 0 0 #014976;
	color: #014976;
	font-size: 1rem;
	line-height: 1.25rem;
}

.header-logo {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 1rem;
	margin-bottom: 4rem;
	float: left;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
	-webkit-box-ordinal-group: 0;
	-webkit-order: -1;
	-ms-flex-order: -1;
	order: -1;
	color: #fff;
	font-size: 1.625rem;
	line-height: 1.25rem;
	font-weight: 600;
	border-bottom: 0px;
}

.header-logo:hover {
	color: #fff;
}

.header-logo.w--current {
	display: block;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
}

.footer-section {
	position: relative;
	padding-top: 2rem;
	background-color: #212931;
	color: #8f99a2;
	font-size: 0.875rem;
	line-height: 1.375rem;
}

.logo-image {
	width: 285px;
	float: left;
}

.footer-disclaimer-section p {
	font-size: 0.8rem;
	line-height: 1.1rem;
	color: silver;
}

.footer-signoff-section {
	margin-top: 1rem;
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
	background-color: rgba(0, 0, 0, 0.1);
	font-size: 0.75rem;
	line-height: 1rem;
}

.copyright-text {
	display: inline-block;
	margin-right: 1.5rem;
	color: silver;
}

.footer-signoff-grip {
	width: 130px;
	height: 28px;
	float: right;
	border-bottom-style: none;
	border-bottom-width: 0px;
	background-position: 50% 50%;
	background-size: 130px 58px;
	background-repeat: no-repeat;
	color: #606060;
	font-size: 8px;
	line-height: 9px;
	font-weight: 600;
	text-transform: uppercase;
}

.footer-signoff-grip:hover {
	color: #606060;
}

.footer-column-title {
	margin-bottom: 0.75rem;
	color: #fff;
	font-size: 1.125rem;
	line-height: 1.5rem;
	font-weight: 600;
}

.footer-column-title h1.footer-title {
	margin-bottom: 0.75rem;
	margin-top: 0px;
	color: #fff;
	font-size: 1.125rem;
	line-height: 1.5rem;
	font-weight: 600;
	font-family: 'Open Sans', sans-serif;
	padding: 0px;
}


.header-content-right-wrap {
	position: absolute;
	right: 0px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 1.25rem;
	margin-bottom: 1.5rem;
	float: right;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
}

.breadcrumb-list {
	margin-top: 0rem;
	margin-right: 0rem;
	margin-bottom: 0.75rem;
	padding-left: 0rem;
	font-size: 0.75rem;
	line-height: 1rem;
	text-transform: uppercase;
}

.breadcrumb-list-item {
	margin-top: 0.25rem;
	margin-right: 0.5rem;
	margin-bottom: 0.25rem;
	float: left;
}

.list-item {
	margin-bottom: 0.35rem;
}

.form-field-label {
	font-weight: 600;
}

.form-field {
	position: relative;
	width: auto;
	margin-bottom: 0.75em;
	padding: 4px 10px;
	font-size: 0.875em;
}

.form-field.header-search-field {
	width: 134px;
	height: 2.25rem;
	margin-bottom: 0em;
	border: 0px none transparent;
	background-color: hsla(0, 0%, 100%, 0.15);
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	color: #fff;
	font-size: 0.75em;
}

.form-field.header-search-field:focus {
	width: 220px;
	border: 0px none transparent;
	background-color: rgba(33, 41, 49, 0.15);
}

.form-success {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	background-color: #b6e498;
	color: #444;
}

.form-error {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	background-color: #f8c8ad;
}

.breadcrumb-divider {
	margin-left: 0.5rem;
	float: right;
}

.left-navigation-column {
	display: inline-block;
	width: 23%;
	margin-right: 2%;
	float: left;
	border-right: 1px solid hsla(0, 0%, 47%, 0.2);
}

.right-sidebar-column {
	margin-left: 1%;
	padding-right: 1rem;
	padding-left: 1rem;
	float: right;
	border-left: 1px solid hsla(0, 0%, 47%, 0.2);
}

.right-sidebar-column h2 {
	font-size: 1.5rem;
	line-height: 2rem;
}

.right-sidebar-column p {
	font-size: 0.875rem;
	line-height: 1.35rem;
}

.left-nav-list {
	margin-top: 0rem;
	margin-bottom: 1.5rem;
	padding-left: 0px;
}

.left-nav-list-item {
	margin-bottom: 0px;
}

.left-nav-list-link {
	display: block;
	padding: 0.75rem 1rem 0.75rem 1.125rem;
	border-bottom: 1px solid hsla(0, 0%, 47%, 0.2);
	color: #50748a;
	line-height: 1.5rem;
	font-weight: 600;
}

.left-nav-list-link.has-children {
	background-image: url('/20160710165515/assets/images/icon_arrow2_right_blk_26.svg');
	background-position: 3px 50%;
	background-size: 7px 10px;
	background-repeat: no-repeat;
}

.left-nav-list-link:hover {
	background-color: rgba(84, 159, 196, 0.08);
	color: #014976;
}

.left-nav-list-link.w--current {
	background-color: rgba(84, 159, 196, 0.08);
	color: #5d7fa5;
}

.left-nav-nested-list {
	margin-top: 0px;
	margin-bottom: 0px;
	padding-top: 0px;
	padding-bottom: 0px;
	padding-left: 0.75rem;
}

.left-nav-nested-list-item {
	margin-bottom: 0px;
}

.left-nav-nested-list-link {
	display: block;
	padding: 0.75rem 1.5rem 0.75rem 1.125rem;
	border-bottom: 1px solid hsla(0, 0%, 47%, 0.2);
	color: #50748a;
	font-size: 0.8rem;
	line-height: 1rem;
	font-weight: 600;
}

.left-nav-nested-list-link.has-children {
	background-image: url('/20160710165515/assets/images/icon_arrow2_right_blk_26.svg');
	background-position: 3px 50%;
	background-size: 6px 8px;
	background-repeat: no-repeat;
}

.left-nav-nested-list-link:hover {
	background-color: rgba(84, 159, 196, 0.08);
	color: #014976;
	border-bottom: 1px solid hsla(0, 0%, 47%, 0.2);
}

.left-nav-nested-list-link.w--current {
	background-color: rgba(84, 159, 196, 0.08);
	color: #014976;
}

.section-wrap.inside-content-section {
	background-color: rgba(93, 127, 165, 0.15);
}

.secondary-nav-icon {
	width: 1.5rem;
	height: 1.5rem;
	margin-right: 0.5rem;
	padding-top: 3px;
	opacity: 0.4;
}

.secondary-nav-text.phone-text {
	color: #014976;
	line-height: 2rem;
}

.slider {
	display: block;
	width: 100%;
	height: 650px;
	margin-right: auto;
	margin-left: auto;
	background-color: transparent;
}

.slider-arrow {
	position: absolute;
	top: 9rem;
	max-width: 15%;
	margin-top: 0px;
	opacity: 0.55;
	font-size: 2rem;
}

.slider-arrow:hover {
	opacity: 1;
}

.slider-indicator {
	height: 2rem;
	padding-top: 0.5rem;
	font-size: 0.625rem;
}

.home-content-box-wrap {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 30%;
	margin-right: 1.5%;
	margin-left: 1.5%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	box-shadow: 0 0 6px 0 silver;
}

.home-content-box-title {
	margin-top: 0rem;
	font-size: 1.375em;
	line-height: 1.5em;
}

.slider-image {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	display: block;
	width: 100%;
	height: 100%;
	margin-right: auto;
	margin-left: auto;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.slider-image.slide-2 {
	position: relative;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.slider-image.slide-3 {
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.slider-caption-wrap {
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: 0px;
	display: block;
	width: 100%;
	max-width: 1170px;
	margin-right: auto;
	margin-left: auto;
	padding: 1rem 3rem 2rem 3rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	background-color: rgba(1, 73, 118, 0.65);
	-webkit-transform: translate(0px, 0%);
	-ms-transform: translate(0px, 0%);
	transform: translate(0px, 0%);
	color: #fff;
	text-align: center;
}

.inside-page-header-section {
	position: relative;
	height: 400px;
	background-image: url('/20160710165515/assets/images/default-banner.jpg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.inside-content-background {
	display: block;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
	padding-top: 2rem;
	padding-bottom: 3rem;
	background-color: #fff;
}

.main-content-wrap {
	display: inline-block;
	width: 75%;
	padding-right: 1rem;
}

.main-content-wrap.right-margin {
	margin-left: 1%;
}

.main-content-wrap.both-sidebars {
	width: 51%;
}

.main-content-wrap.full-width {
	display: block;
	width: 90%;
	margin-right: auto;
	margin-left: auto;
}

.intro-text {
	margin-top: 0px;
	margin-bottom: 1rem;
	font-size: 1.25rem;
	line-height: 1.75rem;
}

.home-content-box-text-wrap {
	position: relative;
	display: block;
	padding: 1rem 0.75rem 3.5rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.slider-caption-title {
	position: static;
	display: inline-block;
	margin-top: 0.5rem;
	margin-right: 2.5rem;
	margin-bottom: 0.5rem;
	float: left;
	background-color: transparent;
	font-size: 3.5rem;
	line-height: 3.5rem;
	font-weight: 300;
	text-align: left;
	text-transform: uppercase;
	text-shadow: 1px 1px 6px rgba(68, 68, 68, 0.6);
}

.footer-links-list-item {
	margin-bottom: 1px;
	box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.15);
}

.footer-list-link {
	color: #549fc4;
	display: block;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	background-image: url('/20160710165515/assets/images/Arrow4_right_wht_25.svg');
	background-position: 97% 50%;
	background-size: 6px 11px;
	background-repeat: no-repeat;
	border-bottom: 0px;
	font-weight: 600;
	line-height: 1.375rem;
}

.footer-list-link:hover {
	color: #fff;
}

.home-cbox-feature-section {
	display: none;
	padding-top: 3rem;
	padding-bottom: 3rem;
	background-color: hsla(200, 6%, 70%, 0.25);
	text-align: center;
}

.home-cbox-feature-image-link {
	display: block;
	overflow: hidden;
	width: 100px;
	height: 100px;
	max-height: 100px;
	max-width: 100px;
	margin-right: auto;
	margin-left: auto;
	border-radius: 50px;
	background-color: hsla(0, 0%, 100%, 0.5);
	background-image: url('/20160710165515/assets/images/world-shape-public-symbol.svg');
	background-position: 50% 50%;
	background-size: 60% 60%;
	background-repeat: no-repeat;
	box-shadow: 0 0 0 2px #fff;
	text-align: center;
}

.home-cbox-feature-text-wrap {
	padding-top: 0.5rem;
	font-size: 0.875em;
}

.left-nav-list-content-wrap {
	display: block;
	height: auto;
}

.link {
	border-bottom: 1px dotted rgba(84, 159, 196, 0.35);
}

.link:hover {
	border-bottom-style: solid;
	border-bottom-color: #549fc4;
}

.link.w--current {
	color: #28313b;
	font-weight: 700;
}

.link.footer-text-link {
	display: inline-block;
	margin-right: 1rem;
	color: #549fc4;
	font-weight: 600;
}

.link.footer-text-link:hover {
	border-bottom-color: #50748a;
	color: #fff;
}

.md-doctor-form-reveal-wrap {
	display: none;
	overflow: hidden;
}

.md-advanced-search-tooltip-popup {
	position: absolute;
	left: -1rem;
	bottom: 100%;
	display: none;
	width: 155px;
	margin-bottom: 0.5rem;
	padding: 0.75rem 1.125rem 1.125rem 0.875rem;
	border-radius: 3px;
	background-color: #28313b;
	box-shadow: 0 0 20px 5px rgba(40, 49, 59, 0.25);
	color: #fff;
	font-size: 0.8125em;
	line-height: 1.375em;
}

.md-patient-comments-reveal-more {
	overflow: hidden;
}

.shop-item-row-wrap-grid-view {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.table-style-embed {
	margin-bottom: 1rem;
}

.loc-sidebar-form-reveal {
	overflow: hidden;
	height: auto;
}

.loc-refine-search-button-image {
	width: 0.75em;
	height: 0.75em;
	margin-top: 3px;
}

.pagination-view-by-button {
	width: 2.25rem;
	height: 2.25rem;
	margin-left: 0.5rem;
	border: 1px solid #000;
	opacity: 0.35;
}

.pagination-view-by-button:hover {
	opacity: 1;
}

.nav-drop-wrap {
	position: relative;
	display: inline-block;
	margin-right: 1px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-flex: 1;
	-webkit-flex: 1 0 auto;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.nav-drop-toggle {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding: 0px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-ordinal-group: 0;
	-webkit-order: -1;
	-ms-flex-order: -1;
	order: -1;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.nav-drop-toggle.w--open {
	box-shadow: none;
	color: hsla(0, 0%, 65%, 0.8);
}

.nav-drop-list {
	overflow: hidden;
	background-color: #e2e2e4;
}

.nav-drop-list.w--open {
	left: 0px;
	top: 100%;
	padding-top: 0.25rem;
	padding-bottom: 0.75rem;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	background-color: #b4b4b4;
	color: #fff;
}

.nav-drop-list-link {
	padding: 0.5rem 1rem;
	border-bottom: 1px solid #ccc;
	color: #014976;
	font-size: 0.9rem;
	font-weight: 600;
}

.nav-drop-list-link:hover {
	background-color: #014976;
	border-bottom: 1px solid #ccc;
	color: #fff;
}

.nav-drop-list-link.w--current {
	background-color: #014976;
	color: #fff;
}

.nav-drop-toggle-link {
	width: 100%;
	padding: 0.75rem 1.25rem;
	color: #014976;
	font-size: 1rem;
	line-height: 1.25rem;
	font-weight: 400;
	text-align: center;
	border-bottom: 0px;
}

.nav-drop-toggle-link:hover,
.nav-drop-wrap:hover .nav-drop-toggle-link {
	background-color: rgba(93, 127, 165, 0.15);
	box-shadow: 0 -3px 0 0 #014976;
	color: #014976;
}

.nav-drop-toggle-link.w--current {
	background-color: rgba(93, 127, 165, 0.15);
	box-shadow: 0 -3px 0 0 #014976;
}

.shop-item-row-wrap-list-view {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.secondary-header-item-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 1rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	color: #50748a;
	font-size: 1.5rem;
	line-height: 2rem;
	font-weight: 700;
}

.secondary-header-item-cta-link {
	margin-right: 0.5rem;
	margin-left: 1rem;
	padding: 0.65rem 2.5rem 0.65rem 1.5rem;
	-webkit-align-self: stretch;
	-ms-flex-item-align: stretch;
	align-self: stretch;
	border-radius: 1.5rem;
	background-color: #506e8f;
	background-image: url('/20160710165515/assets/images/icon_arrow2_right_wht_50.svg');
	background-position: 85% 50%;
	background-size: 10px 10px;
	background-repeat: no-repeat;
	color: #fff;
	font-size: 0.875rem;
	line-height: 1.25rem;
	text-transform: uppercase;
	border-bottom: 0px;
	font-weight: 600;
}

.secondary-header-item-cta-link:hover {
	background-color: rgba(0, 0, 0, 0.5);
	color: #fff;
	border-bottom: 0px;
}

.header-search-wrap {
	margin-bottom: 0px;
	margin-left: 0.25rem;
}

.header-search-form {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.home-content-box-section {
	padding-top: 3rem;
	padding-bottom: 3rem;
}

.cb-title-link {
	display: inline;
	color: #014976;
	font-size: 1.35rem;
	line-height: 1.875rem;
	text-decoration: none;
	margin: 0px;
	font-weight: 600;
	letter-spacing: 0.16px;
}

.cb-title-link a {
	color: #014976;
	font-size: 1.35rem;
	line-height: 1.875rem;
	text-decoration: none;
	border-bottom: 1px dotted rgba(84, 159, 196, 0.35);
}

.cb-title-link a:hover {
	border-bottom-style: dotted;
	color: #5d7fa5;
	border-bottom: 1px dotted rgba(84, 159, 196, 0.35);
}

.cb-title-wrap {
	margin-bottom: 1rem;
}

.cb-more-link {
	position: absolute;
	left: 0px;
	bottom: 0px;
	display: inline-block;
	margin-bottom: 1.5rem;
	margin-left: 0.75rem;
	padding-right: 0.9rem;
	background-image: url('/20160710165515/assets/images/icon_arrow2_right_blk_25.svg');
	background-position: 100% 55%;
	background-size: 10px 10px;
	background-repeat: no-repeat;
	color: #5d7fa5;
	line-height: 1.5rem;
	font-weight: 600;
	border-bottom: 0px;
}

.cb-more-link:hover {
	color: rgba(1, 73, 118, 0.9);
}

.cb-image {
	display: block;
	width: 100%;
	height: 200px;
}

.cb-image.workers-comp-photo {
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.cb-image.personal-injury-photo {
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.cb-image.bankruptcy-photo {
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.cb-image.insurance-law-photo {
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.cb-image.additional-cb {
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.cb-image.another-cb {
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.home-cta-section {
	background-color: #014976;
}

.cta-link {
	display: inline-block;
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	color: #fff;
	font-size: 1.5rem;
	line-height: 2rem;
	font-weight: 400;
	text-align: center;
}

.cta-link a {
	background-image: url('/20160710165515/assets/images/icon_arrow2_right_wht_50.svg');
	background-position: 100% 50%;
	background-size: 12px 12px;
	background-repeat: no-repeat;
	border-bottom: 0px;
	padding-right: 1.125rem;
}

.cta-link a:hover {
	color: #9eaab8;
}

.calendar-image {
	display: inline-block;
	width: 35px;
	height: 35px;
	margin-right: 1rem;
	background-position: 0px 0px;
	background-size: contain;
	background-repeat: no-repeat;
}

.footer-column {
	display: inline-block;
	width: 25%;
}

.footer-column.half-width {
	display: block;
	width: 45%;
}

.footer-text {
	display: block;
	margin-top: 0.75rem;
	color: silver;
	font-size: 0.875rem;
	line-height: 1.35rem;
}

.footer-link {
	display: inline-block;
	border-bottom: 1px dotted rgba(84, 159, 196, 0.35);
	color: #549fc4;
	font-weight: 600;
	line-height: 1.375rem;
}

.footer-link:hover {
	color: #fff;
	border-bottom: 1px dotted rgba(84, 159, 196, 0.35);
}

.footer-link-wrap {
	margin-top: 0.5rem;
}

.footer-link-social {
	justify-content: center;
}

.footer-facebook-link {
	width: 32px;
	height: 32px;
	margin-top: 0.5rem;
	margin-right: 0.5rem;
}

.footer-facebook-link:hover {
	opacity: 0.7;
}

.footer-linkedin-link {
	width: 32px;
	height: 32px;
	margin-top: 0.5rem;
}

.footer-linkedin-link:hover {
	opacity: 0.7;
}

.left-column {
	display: inline-block;
	width: 50%;
}

.right-column {
	display: inline-block;
	width: 50%;
}

.home-about-section {
	padding-top: 2rem;
	padding-bottom: 2rem;
	background-color: rgba(1, 73, 118, 0.15);
}

.about-section-header {
	margin-top: 1rem;
	margin-bottom: 1.25rem;
	color: #014976;
	font-size: 1.5rem;
	line-height: 2rem;
	font-weight: 600;
	text-align: center;
	text-transform: uppercase;
}

.about-cb-wrap {
	display: block;
	width: 85%;
	margin-right: auto;
	margin-left: auto;
}

.left-paragraph {
	display: inline-block;
	width: 46%;
	float: left;
}

.right-paragraph {
	display: inline-block;
	width: 46%;
	float: right;
}

.rectangle-logo-div {
	display: block;
	width: 75px;
	height: 53px;
	margin-right: auto;
	margin-left: auto;
	background-image: url('/20160710165515/assets/images/square-logo.png');
	background-position: 0px 0px;
	background-size: cover;
	background-repeat: no-repeat;
}

.footer-link-div {
	display: inline-block;
}

.footer-links-wrap {
	display: inline-block;
}

.breadcrumb-link {
	border-bottom: 1px dotted rgba(84, 159, 196, 0.35);
	color: #50748a;
	line-height: 1rem;
	font-weight: 600;
}

.breadcrumb-link:hover {
	border-bottom-style: none;
	border-bottom-color: transparent;
	color: #444;
}

.h1-link {
	display: inline;
	margin-bottom: 1rem;
	border-bottom: 1px dotted rgba(84, 159, 196, 0.35);
	color: #014976;
	font-size: 2.25rem;
	line-height: 2.75rem;
}

.h1-link:hover {
	color: #5d7fa5;
}

.text-link {
	display: inline-block;
	margin-bottom: 1rem;
	border-bottom: 1px dotted rgba(84, 159, 196, 0.35);
	color: #014976;
	line-height: 1.5rem;
	font-weight: 400;
}

.text-link:hover {
	border-bottom-color: transparent;
	color: #444;
}

.h2-link {
	border-bottom: 1px dotted rgba(84, 159, 196, 0.8);
	color: #014976;
	font-size: 2rem;
	line-height: 2.5rem;
}

.h2-link:hover {
	color: #5d7fa5;
}

.h3-link {
	border-bottom: 1px dotted rgba(84, 159, 196, 0.35);
	color: #014976;
	font-size: 1.75rem;
	line-height: 2.25rem;
}

.h3-link:hover {
	color: #5d7fa5;
}

.h4-link {
	border-bottom: 1px dotted rgba(84, 159, 196, 0.35);
	color: #014976;
	font-size: 1.5rem;
	line-height: 2rem;
}

.h4-link:hover {
	border-bottom-color: transparent;
	color: #5d7fa5;
}

.h5-link {
	border-bottom: 1px dotted rgba(84, 159, 196, 0.35);
	color: #014976;
	font-size: 1.25rem;
	line-height: 1.75rem;
}

.h5-link:hover {
	border-bottom-color: transparent;
	color: #5d7fa5;
}

.h6-link {
	border-bottom: 1px dotted rgba(84, 159, 196, 0.35);
	color: #014976;
	line-height: 1.5rem;
}

.h6-link:hover {
	border-bottom-color: transparent;
	color: #5d7fa5;
}

.navbar-section {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	z-index: 2;
	border-top: 4px solid #014976;
	background-image: -webkit-linear-gradient(270deg, hsla(0, 0%, 100%, 0.9), hsla(0, 0%, 100%, 0.9));
	background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.9), hsla(0, 0%, 100%, 0.9));
}

.navbar {
	position: static;
	background-color: transparent;
}

.nav-menu {
	position: absolute;
	right: 0px;
	bottom: 0px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
}

.styles {
	display: block;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
}

.unordered-list {
	margin-top: 0rem;
	margin-bottom: 10px;
	padding-left: 40px;
}

.ul-text-block {
	margin-bottom: 0.35rem;
}

.ordered-list {
	margin-top: 0rem;
	margin-bottom: 10px;
	padding-left: 40px;
}

.block-quote {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1.25rem;
	border-left-color: #014976;
	background-color: rgba(84, 159, 196, 0.07);
	font-size: 1.125rem;
	line-height: 1.5rem;
}

.button-link-wrap {
	margin-bottom: 1rem;
}

.right-sidebar-wrap {
	width: 22%;
	float: right;
}

.divider {
	height: 1px;
	margin-top: 1.5em;
	margin-bottom: 1.5em;
	clear: both;
	background-color: rgba(0, 0, 0, 0.15);
}

html.w-mod-js *[data-ix="md-patient-comments-reveal-on-load"] {
	height: 0px;
}

html.w-mod-js *[data-ix="for-two-part-button-list"] {
	-webkit-transform: translate(100%, 0px);
	-ms-transform: translate(100%, 0px);
	transform: translate(100%, 0px);
}

html.w-mod-js *[data-ix="display-none-on-page-load"] {
	display: none;
}

html.w-mod-js *[data-ix="for-grid-view-button"] {
	opacity: 0.7500000000000003;
}

html.w-mod-js *[data-ix="preloader-wrap"] {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

html.w-mod-js *[data-ix="hide-mobile-header-on-load"] {
	height: 0px;
}

@media (max-width: 991px) {
	.button.header-search-button {
		width: 4rem;
		height: 3rem;
		font-size: 1rem;
	}

	.container {
		padding-right: 20px;
		padding-left: 20px;
	}

	.container.header-wrap {
		z-index: 20;
	}

	.container.content-boxes-wrap {
		padding-bottom: 0.5rem;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-align-content: stretch;
		-ms-flex-line-pack: stretch;
		align-content: stretch;
	}

	.nav-link {
		display: block;
		width: 100%;
		margin-right: 0px;
		padding: 1em 1rem;
		border-bottom: 1px solid hsla(0, 0%, 100%, 0.5);
		box-shadow: none;
		text-align: left;
	}

	.nav-link:hover {
		background-color: #fff;
		box-shadow: none;
	}

	.nav-link.w--current {
		background-color: #014976;
		box-shadow: none;
		color: #fff;
	}

	.header-logo {
		margin-bottom: 2.5rem;
		font-size: 1.375rem;
	}

	.header-logo.w--current {
		margin-bottom: 2.5rem;
		margin-left: 0.75rem;
	}

	.footer-section {
		display: block;
	}

	.footer-signoff-section {
		text-align: left;
	}

	.copyright-text {
		display: inline-block;
	}

	.header-content-right-wrap {
		margin-right: 0.75em;
		padding-bottom: 0rem;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		align-self: center;
	}

	.form-field.header-search-field {
		width: 70%;
		height: 3rem;
		padding-left: 0.75em;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
		font-size: 1em;
	}

	.right-sidebar-column {
		width: 100%;
		margin-left: 0%;
		padding-top: 1rem;
		padding-right: 0rem;
		padding-left: 0rem;
		border-top: 1px solid hsla(0, 0%, 47%, 0.2);
		border-left-color: transparent;
	}

	.left-nav-list {
		margin-bottom: 1.5em;
	}

	.slider {
		height: 600px;
	}

	.slider-arrow {
		top: 8rem;
		bottom: 7rem;
		max-width: 8%;
		font-size: 1.75rem;
	}

	.home-content-box-wrap {
		width: 30%;
		margin: 0.5rem 1.5% 1rem;
		float: left;
	}

	.slider-caption-wrap {
		padding-left: 1.5rem;
		padding-right: 1.5rem;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.inside-content-background {
		padding-top: 1.5rem;
		padding-bottom: 2rem;
	}

	.main-content-wrap {
		width: 100%;
	}

	.main-content-wrap.right-margin {
		margin-left: 0%;
	}

	.main-content-wrap.both-sidebars {
		width: 100%;
	}

	.main-content-wrap.full-width {
		width: 100%;
	}

	.home-content-box-text-wrap {
		width: 100%;
		float: right;
	}

	.slider-caption-title {
		font-size: 3rem;
		line-height: 3rem;
	}

	.home-cbox-feature-section {
		margin-top: 1rem;
		padding-bottom: 1rem;
	}

	.home-cbox-feature-image-link {
		max-height: 80px;
		max-width: 80px;
	}

	.left-nav-list-content-wrap {
		overflow: hidden;
		height: 0px;
	}

	.md-doctor-form-reveal-wrap {
		display: none;
	}

	.loc-sidebar-form-reveal {
		height: 0px;
	}

	.nav-drop-wrap {
		display: block;
		width: 100%;
		border-bottom: 1px solid hsla(0, 0%, 100%, 0.5);
		border-right-style: none;
		border-right-color: transparent;
	}

	.nav-drop-wrap:hover {
		box-shadow: none;
	}

	.nav-drop-toggle {
		display: block;
		box-shadow: none;
	}

	.nav-drop-toggle:hover {
		background-color: hsla(0, 0%, 100%, 0.75);
		box-shadow: none;
	}

	.nav-drop-toggle-link {
		display: block;
		padding-top: 1rem;
		padding-bottom: 1rem;
		padding-left: 1rem;
		text-align: left;
	}

	.nav-drop-toggle-link:hover {
		background-color: #fff;
		box-shadow: none;
	}

	.nav-drop-toggle-link.w--current {
		background-color: #014976;
		box-shadow: 1px 1px 3px 0 transparent;
		color: #fff;
	}

	.two-part-button-list {
		position: absolute;
		left: 0px;
		top: 0px;
		z-index: 150;
		display: block;
		width: 100%;
		margin-left: 100%;
		padding-bottom: 50vh;
		background-color: #212931;
	}

	.secondary-header-item-wrap {
		margin-right: 0rem;
		margin-left: 0rem;
		font-size: 1.5rem;
	}

	.secondary-header-item-cta-link {
		margin-left: 1.5rem;
	}

	.header-search-wrap {
		margin-left: 0rem;
		padding: 1rem;
	}

	.header-mobile-search-reveal {
		overflow: hidden;
	}

	.home-content-box-section {
		padding-top: 2rem;
		padding-bottom: 1.5rem;
	}

	.cb-image.workers-comp-photo {
		background-size: cover;
	}

	.home-about-section {
		padding-bottom: 1.5rem;
	}

	.about-cb-wrap {
		width: 90%;
	}

	.navbar-section {
		z-index: 5;
	}

	.nav-menu {
		position: absolute;
		right: 0px;
		bottom: 0px;
		z-index: 10;
		width: 70%;
		margin-top: 127px;
		background-color: #c8c8c8;
	}

	.updated-menu-button {
		position: absolute;
		right: 0px;
		bottom: 0px;
		z-index: 5;
		margin-right: 0.75rem;
		padding: 1rem;
	}

	.updated-menu-button.w--open {
		background-color: #c8c8c8;
	}

	.right-sidebar-wrap {
		width: 100%;
	}

	.container.content-boxes-wrap {
		padding-right: 10px;
		padding-bottom: 0rem;
		padding-left: 10px;
	}
}

@media (max-width: 767px) {
	body {
		font-size: 0.875rem;
		line-height: 1.375rem;
	}

	h1 {
		font-size: 2rem;
		line-height: 2.5rem;
	}

	h2 {
		font-size: 1.75rem;
		line-height: 2.25rem;
	}

	h3 {
		font-size: 1.5rem;
		line-height: 2rem;
	}

	h4 {
		font-size: 1.35rem;
		line-height: 1.85rem;
	}

	h5 {
		font-size: 1.125rem;
		line-height: 1.65rem;
	}

	p {
		font-size: 1rem;
	}

	.button {
		font-size: 1rem;
	}

	.container {
		padding-right: 10px;
		padding-left: 10px;
	}

	.container.header-wrap {
		padding-bottom: 3.75rem;
	}

	.container.footer-flex {
		position: relative;
		display: block;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.header-logo {
		display: block;
		margin-bottom: 0.5rem;
		margin-left: 0rem;
		padding-left: 0px;
		float: none;
		border-bottom: 0px;
	}

	.header-logo.w--current {
		display: block;
		width: 250px;
		margin-right: auto;
		margin-bottom: 0.5rem;
		margin-left: auto;
		float: none;
	}

	.logo-image {
		display: block;
		width: 250px;
		margin-right: auto;
		margin-left: auto;
		float: none;
	}

	.copyright-text {
		display: block;
		margin-right: auto;
		margin-bottom: 0.75rem;
		margin-left: auto;
		text-align: center;
	}

	.footer-signoff-grip {
		display: block;
		margin-right: auto;
		margin-left: auto;
		float: none;
	}

	.footer-column-title {
		padding-right: 0.5rem;
	}

	.header-content-right-wrap {
		position: absolute;
		left: 0px;
		right: auto;
		bottom: 0px;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-top: 0rem;
		margin-bottom: 0.75rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: reverse;
		-webkit-flex-direction: row-reverse;
		-ms-flex-direction: row-reverse;
		flex-direction: row-reverse;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.list-item {
		font-size: 1rem;
		line-height: 1.5rem;
	}

	.right-sidebar-column {
		margin-top: 1.5rem;
		padding-top: 1.5rem;
	}

	.secondary-nav-icon {
		width: 1.25rem;
		height: 1.25rem;
	}

	.secondary-nav-text.phone-text {
		font-size: 1.25rem;
	}

	.slider {
		height: 550px;
	}

	.slider-arrow {
		top: 9rem;
		bottom: 5rem;
	}

	.slider-indicator {
		font-size: 0.5rem;
	}

	.home-content-box-wrap {
		width: 100%;
		padding-bottom: 0rem;
	}

	.slider-image {
		position: relative;
	}

	.slider-image.slide-2 {
		height: 100%;
	}

	.slider-image.slide-3 {
		height: 100%;
	}

	.slider-caption-wrap {
		padding: 0.75rem 1.5rem 2rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	.inside-page-header-section {
		min-height: 80px;
		background-position: 50% 50%;
	}

	.slider-caption-title {
		display: block;
		font-size: 2rem;
		line-height: 2rem;
	}

	.link.footer-text-link {
		margin-right: 1.5rem;
	}

	.secondary-header-item-cta-link {
		margin-right: 1.5rem;
		margin-left: 0.75rem;
		padding: 0.5rem 1.75rem 0.5rem 1.25rem;
		background-position: 90% 50%;
		background-size: 8px 8px;
	}

	.cb-more-link:hover {
		color: #014976;
	}

	.footer-column {
		width: 40%;
	}

	.footer-column.half-width {
		display: block;
		width: 80%;
		margin-top: 1rem;
		margin-right: auto;
		margin-left: auto;
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-ms-flex-order: 1;
		order: 1;
	}

	.footer-column.quick-links {
		position: absolute;
		top: 0px;
		right: 3rem;
		width: 40%;
		padding-left: 25px;
	}

	.footer-column.contact-info-div {
		margin-left: 10%;
	}

	.footer-disclaimer-section.container {
		width: 80%;
		margin-right: auto;
		margin-left: auto;
	}

	.home-about-section {
		padding-top: 1.5rem;
	}

	.about-cb-wrap {
		width: 90%;
	}

	.left-paragraph {
		width: 100%;
		margin-bottom: 0.25rem;
	}

	.right-paragraph {
		width: 100%;
	}

	.footer-links-wrap {
		display: block;
		margin-right: auto;
		margin-bottom: 0.75rem;
		margin-left: auto;
		text-align: center;
	}

	.h1-link {
		font-size: 2rem;
		line-height: 2.5rem;
	}

	.text-link {
		font-size: 1rem;
	}

	.h2-link {
		font-size: 1.75rem;
		line-height: 2.25rem;
	}

	.h3-link {
		font-size: 1.5rem;
		line-height: 2rem;
	}

	.h4-link {
		font-size: 1.35rem;
		line-height: 1.85rem;
	}

	.h5-link {
		font-size: 1.125rem;
		line-height: 1.65rem;
	}

	.h6-link {
		font-size: 1rem;
	}

	.nav-menu {
		margin-top: 147px;
	}

	.unordered-list {
		padding-left: 20px;
	}

	.ordered-list {
		padding-left: 15px;
	}
}

@media (max-width: 479px) {
	.container.header-wrap {
		padding-bottom: 2.5rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.container.centered {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.header-logo {
		margin-right: auto;
		margin-bottom: 0.75rem;
		margin-left: auto;
	}

	.header-logo.w--current {
		width: 230px;
		margin-top: 1rem;
		margin-bottom: 0.75rem;
	}

	.footer-signoff-section {
		padding-bottom: 1rem;
	}

	.header-content-right-wrap {
		position: static;
		width: 100%;
		margin-right: 0em;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}

	.secondary-nav-icon {
		margin-right: 0.25rem;
	}

	.secondary-nav-text.phone-text {
		font-size: 1rem;
		line-height: 1.25rem;
	}

	.slider {
		height: 500px;
	}

	.slider-arrow {
		max-width: 15%;
		font-size: 1.25rem;
	}

	.home-content-box-wrap {
		margin-bottom: 1rem;
	}

	.slider-caption-wrap {
		padding: 0.5rem 1rem 1rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.intro-text {
		font-size: 1.125rem;
		line-height: 1.5rem;
	}

	.home-content-box-text-wrap {
		width: 100%;
		padding-top: 1rem;
		float: none;
	}

	.slider-caption-title {
		font-size: 1.75rem;
	}

	.home-cbox-feature-image-link {
		max-width: 60px;
	}

	.link.footer-text-link {
		margin-right: 0rem;
	}

	.secondary-header-item-cta-link {
		display: block;
		margin-right: 1rem;
		margin-left: 0rem;
		padding: 0.5rem 1.5rem 0.5rem 1rem;
		font-size: 0.8rem;
	}

	.home-content-box-section {
		padding-top: 1.5rem;
	}

	.cta-link {
		margin-top: 0.5rem;
		margin-bottom: 1.25rem;
		font-size: 1.25rem;
	}

	.calendar-image {
		margin-top: 0.75rem;
	}

	.footer-column.half-width {
		margin-top: 0rem;
		margin-bottom: 1rem;
		text-align: center;
	}

	.footer-column.quick-links {
		position: static;
		display: block;
		width: 60%;
		margin-right: auto;
		margin-left: auto;
		padding-left: 0px;
	}

	.footer-column.contact-info-div {
		display: block;
		width: 80%;
		margin-right: auto;
		margin-left: auto;
		text-align: center;
	}

	.footer-link-social {
		display: flex;
	}

	.footer-link-div {
		display: block;
		margin-bottom: 0.75rem;
	}

	.navbar {
		position: relative;
		top: 50px;
		background-color: #dad9d9;
	}

	.nav-menu {
		margin-top: 48px;
	}

	.updated-menu-button {
		padding: 0.75rem;
	}

	.block-quote {
		font-size: 1rem;
	}
}

/****************** Webflow Styles *****************/
/*************************************************/
html {
	font-size: 16px;
}

body {
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
}

.footer-section a[href^=tel] {
	-webkit-transition: all 150ms ease;
	color: #549fc4;
	letter-spacing: 0.01em;
	text-decoration: none;
}

input::-webkit-input-placeholder {
	color: #b4bfcc !important;
}

input::-moz-placeholder {
	color: #b4bfcc !important;
}

input:-moz-placeholder {
	color: #b4bfcc !important;
}

input:-ms-input-placeholder {
	color: #b4bfcc !important;
}
/* PREVENT TEXT INSIDE THE CARDS FROM SQUISHING ON IE */
@media all and (-ms-high-contrast: none) {
	/*this targets IE only*/
	.home-content-box-wrap,
	.home-content-box-text-wrap {
		display: block;
	}
}

/****************** Tablesaw ***********************/
/****************************************************/
@media (max-width: 39.9375em) {
	.tablesaw.styled tr {
		border-bottom: 2px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr td {
		border-right: 0px solid;
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr:last-child,
	.tablesaw.styled tr:last-child td:last-child {
		border-bottom: none;
	}
}

/****************** Breakpoints ********************/
/****************************************************/
[data-breakpoints] {
	display: none !important;
}

@media screen and ( min-width: 991px ) {
	[data-breakpoints*="xl"] {
		display: block !important;
	}
}

@media screen and ( min-width: 767px ) {
	[data-breakpoints*="md,lg"] {
		display: block !important;
	}
}

[data-breakpoints*="xs,sm"] {
	display: block !important;
}

/****************** Print ***********************/
/*************************************************/
#printFooter {
	display: none;
	visibility: hidden;
}

#printHeader {
	display: none;
	visibility: hidden;
}

/*********************** Header **********************/
/*****************************************************/
.phone-text.desktop {
	display: block;
}

.phone-text.mobile {
	display: none;
	border-bottom: 0px;
	font-weight: 700;
}

.nav-drop-toggle.active a {
	background-color: rgba(93, 127, 165, 0.15);
	box-shadow: 0 -3px 0 0 #014976;
}


.nav-drop-toggle.w-dropdown-toggle.active a {
	background-color: rgba(93, 127, 165, 0.15);
	box-shadow: 0 -3px 0 0 #014976;
}

.has-dropdown.open .nav-drop-list {
	left: 0px;
	top: 100%;
	padding-top: 0.25rem;
	padding-bottom: 0.75rem;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	background-color: #b4b4b4;
	color: #fff;
	display: block;
}

@media (max-width: 991px) {
	.phone-text.desktop {
		display: none;
	}

	.phone-text.mobile {
		display: block;
	}
}

/****************** Mobile Menu **********************/
/*****************************************************/
@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: none !important;
	}
}

@media (max-width: 991px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
	}
}

.mm-wrapper_opened .mobile-menu-button {
	background-color: transparent;
	color: #506e8f;
}

.mobile-navigation-menu {
	background-color: #c8c8c8;
	font-family: 'Open Sans', sans-serif;
}

.mobile-navigation-menu li a.w-nav-link {
	color: #014976;
	font-size: 1rem;
	line-height: 1.25rem;
	font-weight: 400;
	padding-top: 1rem;
	padding-bottom: 1rem;
	padding-left: 1rem;
	border-bottom: 0px;
}

.mobile-navigation-menu li a:hover {
	background-color: #fff;
}

.mobile-navigation-menu li.active a.w-nav-link {
	background-color: #014976;
	box-shadow: none;
	color: #fff;
}

.mm-listitem:after {
	left: 0px;
	border-color: hsla(0, 0%, 100%, 0.5);
}

.mm-listitem .mm-btn_next:before {
	border-color: hsla(0, 0%, 100%, 0.5);
}

.mm-menu .mm-listview .mm-btn_next:after,
.mm-menu .mm-btn:after,
.mm-menu .mm-btn:before {
	border-color: #014976;
}

.mm-navbar {
	text-align: left;
	border-bottom: 1px solid hsla(0, 0%, 100%, 0.5);
	height: 48px;
}

.mm-menu .mm-navbar a,
.mm-menu .mm-navbar > * {
	color: #014976;
	font-size: 1rem;
	line-height: 1.25rem;
	font-weight: 400;
	border-bottom: 0px;
}

.mm-menu .mm-navbar > * {
	padding-top: 14px;
}

.mm-panels > .mm-panel > .mm-navbar + .mm-listview {
	margin-top: -11px;
}

.mm-menu .mm-btn:before {
	top: 9px;
}

/********************* Homepage **********************/
/*****************************************************/
.about-section-header a,
.about-section-header a:hover {
	color: inherit;
	font-weight: inherit;
	font-size: inherit;
	line-height: inherit;
	border-bottom: 0px;
	letter-spacing: normal;
}

.about-cb-wrap {
	display: flex;
	flex-wrap: wrap;
}

.about-cb-wrap p:nth-child(even) {
	width: calc(46% - 1px);
	margin-left: 4%;
}

.about-cb-wrap p:nth-child(odd) {
	width: calc(46% - 1px);
	margin-right: 4%;
}

@media (max-width: 767px) {
	.about-cb-wrap {
		display: block;
	}

	.about-cb-wrap p:nth-child(even),
	.about-cb-wrap p:nth-child(odd) {
		width: 100%;
		margin-left: 0;
		margin-right: 0;
	}
}

/****************** Slideshow ************************/
/*****************************************************/
.slider-caption-title p {
	font-weight: inherit;
	font-family: inherit;
	line-height: inherit;
	color: inherit;
	font-size: inherit;
	margin: 0px;
}

.slider-caption-wrap .button.more {
	float: right;
	margin: 0px;
}

/****************** Inside Page *******************/
/*************************************************/
@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
	}
}

/******************* Right Sidebar *******************/
/*****************************************************/
.right-sidebar-wrap .box {
	font-size: 0.875rem;
	line-height: 1.35rem;
	margin-bottom: 1rem;
}

.right-sidebar-wrap .box p {
	margin-top: 0.5rem;
	margin-bottom: 1rem;
}

.right-sidebar-wrap .box ul {
	font-size: 0.875rem;
	line-height: 1.35rem;
	padding-left: 0px;
}

.right-sidebar-wrap .box h4,
.right-sidebar-wrap .box h3 {
	font-size: 1.5rem;
	margin-top: 0px;
	margin-bottom: 1rem;
	color: #014976;
	line-height: 2rem;
	font-weight: 600;
}

.right-sidebar-wrap .box td {
	vertical-align: top;
	padding-bottom: 0.5rem;
}

.right-sidebar-wrap .contentbox_item_image {
	width: 24px;
}

.right-sidebar-wrap .box.documents td:first-child {
	padding-right: 7px;
}

.right-sidebar-wrap .box.links p {
	margin-bottom: 0.25rem;
	margin-top: 0px;
}

.right-sidebar-wrap .box.tools td:first-child img {
	margin-right: 5px;
}

/*********************** Footer **********************/
/*****************************************************/
#GRIPFooterLogo {
	float: right;
	padding-top: 0px !important;
	line-height: 3px;
}

#GRIPFooterLogo a {
	border-bottom: 0px;
}

.footer-phone.desktop {
	display: inline-block;
}

.footer-phone.mobile {
	display: none;
}

.footer-phone.mobile a {
	color: inherit;
	font-weight: normal;
	line-height: 1.3875rem;
	border-bottom: 0px;
}

.footer-list-link.active {
	color: #fff;
}

@media (max-width: 991px) {
	.footer-phone.mobile {
		display: inline-block;
	}

	.footer-phone.desktop {
		display: none;
	}
}

@media (max-width: 767px) {
	#GRIPFooterLogo {
		float: none;
		margin-left: auto;
		margin-right: auto;
		margin-top: 0.75rem;
	}
}

/************ Inside Page Styles ******************/
/*************************************************/
img {
	height: auto;
	max-width: 100%;
}

a {
	border-bottom: 1px dotted rgba(84, 159, 196, 0.35);
	color: #014976;
	line-height: 1.5rem;
	font-weight: 400;
}

a:hover {
	border-bottom-color: transparent;
	color: #444;
}

/* heading links */
h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
	border-bottom-color: transparent;
	color: #5d7fa5;
}


/* buttons */
.button,
.primary,
.cms_form_button.primary,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary,
button[type=submit] {
	padding: 0.5rem 1.25rem;
	background-color: #014976;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #fff;
	line-height: 1.5rem;
	font-weight: 600;
	text-align: center;
	cursor: pointer;
	text-decoration: none;
	box-shadow: none;
	border: 0;
}

.primary:hover,
.cms_form_button.primary:hover,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary:hover,
button[type=submit]:hover {
	background-color: #28313b;
	color: #fff;
}

.secondary,
.cms_form_button.secondary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary,
.inside-body-section .cms_form_button.secondary {
	padding: 0.5rem 1.25rem;
	background-color: #50748a;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #fff;
	line-height: 1.5rem;
	font-weight: 600;
	text-align: center;
	cursor: pointer;
	text-decoration: none;
	box-shadow: none;
	border: 0;
}

.secondary:hover,
.cms_form_button.secondary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:hover,
.inside-body-section .cms_form_button.secondary:hover {
	background-color: #28313b;
	color: #fff;
}

.tertiary,
.cms_form_button.tertiary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary {
	padding: 0.5rem 1.25rem;
	background-color: #50748a;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #fff;
	line-height: 1.5rem;
	font-weight: 600;
	text-align: center;
	cursor: pointer;
	text-decoration: none;
	box-shadow: none;
	border: 0;
}

.tertiary:hover,
.cms_form_button.tertiary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary:hover {
	background-color: #28313b;
	color: #fff;
}


/* messages */
#message.success {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	background-color: #b6e498;
	color: #444;
}

#message.error {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	background-color: #f8c8ad;
}

#message.success *,
#message.error * {
	color: #444;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 400;
}


/*tables*/
table.styled thead tr th {
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.625rem 0.625rem;
	text-align: left;
	background-color: #014976;
	color: white;
}

table.styled th {
	font-weight: normal;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.5rem 0.625rem;
	text-align: left;
	background-color: #002147;
	color: white;
}

table.styled th,
table.styled td {
	border-right: 1px solid white;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled th a {
	color: inherit;
	font-weight: inherit;
	text-decoration: underline;
	border-bottom: none;
	box-shadow: none;
}

table.styled th a:hover {
	border-bottom: none;
	box-shadow: none;
	text-decoration: none;
}

table.styled td {
	padding: 0.375rem 0.625rem;
	font-size: 0.96rem;
	line-height: 1.4rem;
}

table.styled.striped tr:nth-child(even) {
	background: lightgray;
}

table.styled.striped tr:nth-child(odd) {
	background: white;
}

/* forms */
input[type="text"],
input[type="tel"],
input[type="password"],
input[type="email"],
select,
textarea {
	max-width: 100%;
	padding: 8px 12px;
	margin-bottom: 10px;
	font-size: 14px;
	line-height: 1.428571429;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #cccccc;
}

textarea {
	resize: vertical;
}

label {
	font-weight: 400;
}

@media (max-width: 991px) {
	.inside-page-body-content-wrap input[type="text"],
	.inside-page-body-content-wrap input[type="tel"],
	.inside-page-body-content-wrap input[type="password"],
	.inside-page-body-content-wrap input[type="email"],
	.inside-page-body-content-wrap select,
	.inside-page-body-content-wrap textarea {
		width: 100%;
	}
}

/*blockquotes*/
blockquote p {
	font-size: inherit;
	line-height: inherit;
	margin-bottom: 0;
	margin-top: 0;
}

/*horizontal ruler*/
hr:not(.fc-divider):not(.formBreak) {
	border-color: rgba(0, 0, 0, 0.15);
	border: 0px;
	border-top: 1px solid;
	margin-top: 1.5em;
	margin-bottom: 1.5em;
	color: rgba(0, 0, 0, 0.15);
}

/* pagination */
.pagination-wrapper {
	font-size: 0.9rem;
}

/* date picker */
#ui-datepicker-div {
	font-size: 0.9rem;
}

/************ Content Modules ********************/
/*************************************************/
/* newsletters */
#newsletters_module.entity ul {
	margin: 0px;
	padding: 0px;
	list-style-type: none;
}

#newsletters_module.entity h3,
#newsletters_module.entity h4 {
	margin-top: 0px;
}

#newsletters_module.form .form_item .field {
	padding-bottom: 15px;
}

#newsletters_module.list .item_divider {
	margin-bottom: 1rem;
	margin-top: 1rem;
	border-top: 1px solid rgba(0, 0, 0, 0.15);
}

@media (max-width: 991px) {
	#newsletters_module.form .form_item .label,
	#newsletters_module.form .form_item.required .label {
		width: auto;
		display: inline-block;
		text-align: left;
		padding-bottom: 5px;
	}

	#newsletters_module.form .form_item.required .label {
		background-image: url("/20160710165515/core/public/shared/assets/images/required.gif");
		background-repeat: no-repeat;
		background-position: right center;
		padding-right: 20px;
	}

	#newsletters_module.form .form_item .label label {
		display: inline-block;
	}

	#newsletters_module.form .form_item .field {
		width: 100%;
		display: block;
		padding-left: 0;
	}

	#newsletters_module.form .form_item.required .field {
		background: transparent none;
	}

	#newsletters_module.form .form_item .field table tr td {
		vertical-align: top;
	}

	#newsletters_module.form .form_buttons {
		text-align: left;
	}

	#newsletters_module.form .form_item input[type="text"] {
		width: 100% !important;
	}
}

/* resources */

/* photo albums */
#photoalbums_module.cms_list .cms_item_divider {
	margin-bottom: 1rem;
	margin-top: 1rem;
	border-top: 1px solid rgba(0, 0, 0, 0.15);
}

#photoalbums_module.cms_list .cms_list_item table td.cms_image {
	padding-right: 15px;
}

#photoalbums_module.cms_list .cms_list_item table td.cms_image a img {
	max-width: 150px;
}

@media (max-width: 991px) {
	#photoalbums_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#photoalbums_module.cms_list .cms_list_item table td.cms_image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}
}

/* submisison forms */
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding-top: 1px;
}

#submissionforms_module.cms_form .cms_label div {
	display: inline;
}

#submissionforms_module.cms_form .sigPad input {
	padding: 8px 12px;
}

#submissionforms_module.cms_form .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.cms_form .checkbox_wrapper .item label,
#submissionforms_module.cms_form .radio_wrapper .item label {
	margin: 0px;
	font-size: 0.9rem;
	line-height: normal;
}

#submissionforms_module.cms_form .cms_hint,
#submissionforms_module.cms_form .cms_date_hint,
#submissionforms_module.cms_form .agreement_hint,
#submissionforms_module.cms_form .cms_field_note,
#submissionforms_module.cms_form input[type="file"],
#submissionforms_module.homepage_contentbox .cms_date_hint,
#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 0.8rem;
	color: inherit;
}

#submissionforms_module.cms_form .signature_name {
	display: block;
}

#submissionforms_module.cms_form .sigWrapper.current {
	display: block !important;
}

/*confirmation page*/
.module-forms.confirmation .step-title {
	margin-top: 1rem;
	color: #2a779b;
	font-size: 1.4rem;
	line-height: 1.9rem;
	font-weight: 700;
}

.module-forms.confirmation .value-cell img.signature-image {
	max-width: 100%;
}

.module-forms.confirmation .label-cell {
	font-weight: normal;
}


@media (max-width: 991px) {
	.module-forms.confirmation td {
		display: block;
		width: 100%;
	}

	.module-forms.confirmation .label-cell {
		padding-bottom: 0;
	}

	.module-forms.confirmation .value-cell {
		padding-top: 0;
	}
}

/*************** Content Boxes *******************/
/*************************************************/
/* newsletter */
#submissionforms_module.homepage_contentbox table {
	width: auto;
}

#submissionforms_module.homepage_contentbox input[type="text"],
#submissionforms_module.homepage_contentbox input[type="tel"],
#submissionforms_module.homepage_contentbox input[type="email"],
#submissionforms_module.homepage_contentbox select,
#submissionforms_module.homepage_contentbox textarea {
	width: 100%;
	max-width: 100%;
	padding: 8px 12px;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #ccc;
	font-size: 0.9rem;
	margin-bottom: 10px;
}

#submissionforms_module.homepage_contentbox textarea {
	width: 100% !important;
}

#submissionforms_module.homepage_contentbox .cms_field {
	padding-bottom: 5px;
}

#submissionforms_module.homepage_contentbox input[type="checkbox"],
#submissionforms_module.homepage_contentbox input[type="radio"] {
	margin-right: 3px;
}

#submissionforms_module.homepage_contentbox .sigWrapper .pad {
	max-width: 100%;
}

@media (min-width: 991px) {
	.slider-section #submissionforms_module.homepage_contentbox table td,
	.slider-section #submissionforms_module.homepage_contentbox table a,
	.slider-section #submissionforms_module.homepage_contentbox table a:hover,
	.slider-section #submissionforms_module.homepage_contentbox .cms_hint,
	.slider-section #submissionforms_module.homepage_contentbox .cms_date_hint {
		color: #fff;
		font-weight: normal;
	}
}

/******************** Law Firm *******************/
/*************************************************/
/* practice areas */
#module-practiceareas.list-view .practice-area {
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	margin: 0 0 1.5em;
	padding: 0 0 1em;
}

#module-practiceareas.list-view .practice-area .area-image {
	display: none;
}

#module-practiceareas.list-view .practice-area .area-children,
#module-practiceareas.thumbnail-view .main-list-item-related-areas-list {
	padding-left: 20px;
	border-left: 1px solid rgba(0, 0, 0, 0.15);
	-webkit-column-count: 2;
	-moz-column-count: 2;
	column-count: 2;
	-webkit-column-gap: 40px;
	-moz-column-gap: 40px;
	column-gap: 40px;
	-webkit-column-rule: 1px solid rgba(0, 0, 0, 0.15);
	-moz-column-rule: 1px solid rgba(0, 0, 0, 0.15);
	column-rule: 1px solid rgba(0, 0, 0, 0.15);
}

#module-practiceareas.list-view .practice-area .area-children .column {
	float: none;
	width: 100%;
	display: inline;
}

#module-practiceareas.list-view .practice-area .area-children .column a {
	border-bottom: 0px;
	display: inline-block;
	width: 100%;
	margin-bottom: 4px;
}

#module-practiceareas.thumbnail-view .main-list-related-areas-column,
#module-practiceareas.thumbnail-view .main-list-summary-column {
	width: 100%;
	padding-left: 0px;
	padding-right: 0px;
}

#module-practiceareas.thumbnail-view .main-list-summary-column .main-list-item-summary-wrap {
	padding-right: 0px;
	margin-top: 7px;
}

#module-practiceareas.thumbnail-view .main-list-item-related-areas-title {
	font-size: 1.4rem;
	margin-bottom: 5px;
	margin-top: 10px;
	line-height: 2rem;
}

#module-practiceareas.thumbnail-view .main-list-item-related-areas-list {
	list-style-type: none;
}

#module-practiceareas.thumbnail-view .main-list-item-related-areas-list a {
	border-bottom: 0px;
}

#module-practiceareas.thumbnail-view .main-list-item-wrap {
	padding-bottom: 0.5rem;
}

#module-practiceareas.details-view #area-sidebar {
	background: rgba(93, 127, 165, 0.15);
	width: 40%;
}

#module-practiceareas.details-view #area-main {
	width: calc(60% - 20px) !important;
	margin-right: 20px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

#module-practiceareas.details-view #area-main h4 {
	margin-top: 10px;
}

#module-practiceareas.details-view #area-sidebar #area-attorneys {
	padding: 20px;
}

#module-practiceareas.details-view #area-sidebar #area-attorneys h4 {
	margin-top: 10px;
}

#module-practiceareas.details-view #area-sidebar #area-attorneys ul {
	list-style: disc;
	margin: 0px 0px 0px 40px;
}

@media (max-width: 991px) {
	#module-practiceareas.details-view #area-sidebar,
	#module-practiceareas.details-view #area-main {
		width: 100% !important;
		margin: 0px 0px 0px 0px;
		float: none;
	}

	#module-practiceareas.details-view #area-main {
		margin-bottom: 20px;
		padding-bottom: 20px;
		padding-top: 0px;
	}
}

@media (max-width: 767px) {
	#module-practiceareas.list-view .practice-area .area-children,
	#module-practiceareas.thumbnail-view .main-list-item-related-areas-list {
		-webkit-column-count: 1;
		-moz-column-count: 1;
		column-count: 1;
	}

	#module-attorneys.alpha table tr td:first-child {
		padding-bottom: 0px;
		padding-top: 0px;
	}
}

/* attorneys */
#module-attorneys table a,
#module-attorneys.photo .attorney a {
	color: #014976;
}

#module-attorneys table tr:hover td {
	background-color: rgba(84, 159, 196, 0.08);
}

#module-attorneys.alpha #letter-navigation {
	padding: 10px;
	background: rgba(84, 159, 196, 0.05);
}

#module-attorneys.alpha #letter-navigation a {
	font-weight: bold;
	border-bottom: 0px;
	color: #014976;
}

#module-attorneys.alpha #letter-navigation a.inactive {
	color: #a8aebd;
}

#module-attorneys.alpha table tr td:first-child {
	width: 10% !important;
}

#module-attorneys.alpha table thead tr td,
#module-attorneys.alpha table tr td {
	padding-right: 10px;
	padding-left: 10px;
}

#module-attorneys.alpha table thead tr td {
	color: #50748a;
	padding-bottom: 10px;
	font-size: 0.9rem;
	font-weight: 600;
}

#module-attorneys.alpha .alpha-indicator {
	color: #014976;
}

#module-attorneys.alpha table tr td {
	width: 28% !important;
}

#module-attorneys.practice .section-header {
	border-bottom: 0px;
	color: #014976;
	margin-bottom: 0px;
	padding-bottom: 10px;
	margin-top: 10px;
	font-size: 1.5rem;
	text-transform: none;
}

#module-attorneys.practice .column-headers td {
	color: #50748a;
	padding: 5px 10px 8px 10px;
	text-transform: none;
	font-size: 0.9rem;
	text-transform: uppercase;
	font-weight: 600;
}

#module-attorneys.practice .practice-area td {
	padding-right: 10px;
	padding-left: 10px;
}

#module-attorneys.practice .phone,
#module-attorneys.practice .name {
	width: 29%;
}

#module-attorneys.practice .practice-area-sub-header {
	margin: 0px 0px 0px 0px;
	font-weight: bold;
}

#module-attorneys.photo {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

#module-attorneys.photo .attorney {
	float: none;
	width: 25%;
	margin: 0px 0px 20px 0px;
	text-align: center;
}

#module-attorneys.photo .attorney .image {
	width: auto;
	margin: 0px 0px 5px 0px;
}

#module-attorneys.profile h4 {
	margin-bottom: 0.5rem;
}

#module-attorneys.profile #details {
	display: inline-block;
	width: 100%;
}

#module-attorneys.profile #contact-info {
	width: 100%;
	display: flex;
}

#module-attorneys.profile #contact-info .info,
#module-attorneys.profile #details .details-left {
	float: left;
	width: calc(100% - 380px);
	margin-right: 20px;
}

#module-attorneys.profile #contact-info .info {
	min-height: 200px;
}

#module-attorneys.profile #contact-info .image,
#module-attorneys.profile #details .details-right {
	float: right;
	width: 360px;
}

#module-attorneys.profile #contact-info .image {
	min-height: 200px;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	order: 2;
}

#module-attorneys.profile #contact-info .social-media-info {
	float: left;
	width: 100%;
	display: block;
	margin-bottom: 15px;
}

#module-attorneys.profile #details .details-right {
	background: rgba(93, 127, 165, 0.15);
}

#module-attorneys.profile #details .details-right .content-box {
	padding: 20px;
	width: calc(100% - 40px);
}

#module-attorneys.profile #details .details-right .content-box.practice-areas ul {
	list-style-type: disc;
}

#module-attorneys.profile #details .details-left .details-box {
	border-top: 1px solid rgba(0, 0, 0, 0.15);
	padding-top: 20px;
	margin-bottom: 20px;
}

#module-attorneys.profile #info-download {
	margin: 6px 0px 0px 0px;
}

#module-attorneys.profile #info-download a,
#module-attorneys.profile #contact-info .info .info-box .email-desktop,
#module-attorneys.profile #contact-info .info .info-box .email-desktop:hover {
	color: #014976;
}

#module-attorneys.profile #contact-info .info .info-box .info-icon {
	margin: 0px 0px 0px 0px;
	padding: 1px 0px 1px 28px;
	border-bottom: 0px;
	background-repeat: no-repeat;
	background-size: auto 20px;
	background-position: left center;
}

#module-attorneys.profile #contact-info .info .info-box .vcard {
	background-image: url("/20160710165515/assets/images/vcard.svg");
}

#module-attorneys.profile #contact-info .info .info-box .linkedIn {
	background-image: url("/20160710165515/assets/images/linkedIn.svg");
}

#module-attorneys.profile #contact-info .info .info-box .print {
	background-image: url("/20160710165515/assets/images/print.svg");
}

#module-attorneys.profile #contact-info .info .info-box .email-desktop {
	display: inline;
}

#module-attorneys.profile #contact-info .info .info-box .email-mobile {
	display: none;
	background-image: url("/20160710165515/assets/images/email.svg");
}

#module-attorneys.profile #contact-info .info .info-box .email-desktop,
#module-attorneys.profile #contact-info .info .info-box .email-mobile {
	border-bottom: 0px;
}

#module-attorneys.profile #contact-info .info .social-media-info .info-box.with-background {
	float: left;
	margin: 6px;
}

#module-attorneys.profile #contact-info .info .info-box .label {
	color: #014976;
}

#module-attorneys.profile #details .details-left .content-box {
	display: inline-block;
	width: 100%;
	margin-bottom: 10px;
}

#module-attorneys.profile .content-box h2 {
	color: #014976;
	font-size: 1.6rem;
	line-height: 2rem;
	font-family: 'Open Sans', sans-serif;
	margin: 0px;
	text-transform: none;
}

@media (max-width: 991px) {
	#module-attorneys.profile #contact-info .image,
	#module-attorneys.profile #contact-info .info {
		width: 50%;
	}

	#module-attorneys.profile #details .details-right,
	#module-attorneys.profile #details .details-left {
		width: 100%;
		margin-top: 20px;
	}

	#module-attorneys.profile #contact-info .info {
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);
		padding-bottom: 20px;
	}

	#module-attorneys.profile #contact-info .info .info-box.with-background {
		background: rgba(93, 127, 165, 0.15);
		margin-top: 10px;
		padding: 7px;
		display: inline-block;
		width: 100%;
	}

	#module-attorneys.profile #contact-info .info .info-box .email-desktop {
		display: none;
	}

	#module-attorneys.profile #contact-info .info .info-box .email-mobile {
		display: block;
	}

	#module-attorneys.profile #contact-info .info .social-media-info .info-box.with-background {
		float: none;
		margin: 6px 0px 0px 0px;
	}
}

@media (max-width: 767px) {
	#module-attorneys.alpha #letter-navigation {
		display: none;
	}

	#module-attorneys.alpha table thead {
		display: none;
	}

	#module-attorneys.alpha table tr td {
		display: block !important;
		width: 100% !important;
		padding-left: 0px;
		padding-right: 0px;
		height: auto;
	}

	#module-attorneys.alpha .alpha-indicator {
		padding-bottom: 5px;
	}

	#module-attorneys.practice .column-headers {
		display: none;
	}

	#module-attorneys.practice .practice-area {
		margin-bottom: 10px;
		display: inline-block;
		width: 100%;
	}

	#module-attorneys.practice .practice-area td {
		display: block;
		width: 100%;
	}

	#module-attorneys.practice .section-header {
		margin-top: 0px;
	}

	#module-attorneys.photo .attorney {
		width: 33%;
	}
}

@media (max-width: 479px) {
	#module-attorneys.photo .attorney {
		width: 50%;
	}

	#module-attorneys.profile #contact-info {
		display: block;
	}

	#module-attorneys.profile #contact-info .info,
	#module-attorneys.profile #contact-info .image {
		width: 100%;
	}

	#module-attorneys.profile #contact-info .image {
		margin-bottom: 20px;
	}
}
